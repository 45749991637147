import { useState } from 'react'
import { PrivacyCard } from "../components/PrivacyCard";
import { Blurb } from "../components/Blurb";
import { PrivacyShortcut } from "../components/PrivacyShortcut";
import { PrivacyContent } from "../components/PrivacyContent";

export const Privacy = () => {
  const [content, setContent] = useState("")
  const [count, setCount ] = useState(0);
  const changeContent = (type) => {
    setContent(type)
    setCount((data) => data + 1)
  }
  return (
    <main>
      <section className="py-16">
        <div className="xs-mx-2 md:mx-20 lg:mx-40">
          <PrivacyCard
            image="images/privacy-1.png"
            text="Review My Boss does NOT collect any personal identifiable information (PII) without your permission or active participation."
          />
          <PrivacyCard
            image="images/privacy-2.png"
            text="Review My Boss is a safe and open platform for everyone."
          />
          <PrivacyCard
            image="images/privacy-3.png"
            text="All reviews are completely anonymous."
          />
          <div className="flex justify-center">
            <PrivacyShortcut  changeContent={() => changeContent('privacy')} image="images/privacy.png" />
            <PrivacyShortcut  changeContent={() => changeContent('terms')} image="images/terms.png" />
          </div>
        </div>
      </section>
      <Blurb text="Protecting your private information is our priority." bg="bg-secondary" />
      <PrivacyContent  scrollTo={content} count={count}/>
    </main>
  );
};
