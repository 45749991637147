import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery, scrollToPosition, capitalizeFirstLetter, capitalize } from "../util/helpers";
import { Search } from "../components/Search";
import { Title } from "../components/Title";
import { Pagination } from "../components/Pagination";
import { Rating } from "../components/Rating";
import { Button } from "../components/Button";
import { Spinner } from "../components/Spinner";
import '../scss/result.scss';



export const Results = (props) => {
  const { innerWidth: width } = window;
  const history = useHistory();
  let query = useQuery();
  let boss = query.get("boss") ? history.location.search.slice(6) : null;
  let company = query.get("company") ? history.location.search.slice(9) : null;
  let allResults = query.get("allresults") ? history.location.search.slice(12): null;
  let search = allResults || boss || company;
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUsers] = useState([])
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0)
  const [reviewPosition, setReviewPosition] = useState(0);
  const dataLimit = 10;

  useEffect(() => {
    scrollToPosition(0, 800);
  }, []);

  useEffect(() => {
    setLoading(true)
    if (search) {
      fetch(
        `https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/search-${allResults ? "boss-company" : boss ? "boss" : "company"}?${allResults ? "search" : boss ? "boss" : "company"}=${search.replaceAll('&', '%26')}&offset=0`
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data.length > 0) {
            setCurrentPage(1)
            setPages(Math.ceil(data[0].total_results / dataLimit))
          } 
          scrollToPosition(0, width < 600 ? reviewPosition - 40 : 600);
          setUsers(data);
        })
    }
  }, [search, boss, company, allResults]);

  useEffect(() => {
    setLoading(true)
    fetch(
      `https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/search-${allResults ? "boss-company" : boss ? "boss" : "company"}?${allResults ? "search" : boss ? "boss" : "company"}=${search.replaceAll('&', '%26')}&offset=${(Number(currentPage) * 10) - 10}`
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        scrollToPosition(0, width < 600 ? reviewPosition - 40 : 600);
        setUsers(data)
      })

  }, [currentPage])

  if (!search) {
    return <div>error 404</div>;
  }


  const getCurrentPage = (e) => {
    setCurrentPage(e);
  }
  const onHide = () => {
    setCount((data) => data + 1)
  }

  const scrollToReview = (e) => {
    setReviewPosition(e);
  }

  return (
    <main onClick={onHide}>
      <Search  autocomplete={true} scrollToReview={scrollToReview} show={count} />
      <section className="xs-pt-unset py-32">
        <div className="md:mx-10 lg:mx-40">
          {loading ? <Spinner /> : user.length === 0 ?
            <div>
              <Title className="text-center mb-8 text-primary">No Matching Results Found</Title>
              <div className="flex justify-center">
                <Button
                  size={Button.size.EXTRA_LARGE}
                  color={Button.color.SECONDARY}
                  onClick={() => {
                    history.push(`/review?full_name=${boss ? search : ''}&company=${company ? search : ''}`);
                  }}>Review {company || allResults ? 'Someone ' : ''}Now</Button>
              </div>
            </div> : <div>
              <Title className="text-center mb-8">Results for "{search.replaceAll('%20', ' ')}"</Title>
              <div className="flex justify-around text-center text-primary font-itc font-bold font-10p md:text-base lg:text-xl mb-8 xs-ml xs-mr">
                <div className="w-40 lg-font-22p">{boss ? "Name" : "Company"}</div>
                <div className="w-40 lg-font-22p">{boss ? "Company" : "Name"}</div>
                <div className="w-40 lg-font-22p">Boss' Position</div>
                <div className="w-40 lg-font-22p">Company Location</div>
                <div className="w-40 lg-font-22p">Reviews</div>
                <div className="w-40 lg-font-22p">Rating</div>
              </div>
              {user.map(({ full_name, company, location, position, rt_avg, review_total }, i) => (
                <Link to={`/profile?full_name=${full_name}&position=${position}&company=${company}&location=${location}`}
                  key={i} className="block mb-4 last:mb-0"
                >
                  <div className="flex justify-around items-center border border-black rounded-full py-3 font-bold font-10p md:text-base lg:text-xl xs-ml xs-mr cursor-pointer">
                    <div className="w-40 text-center truncate">
                      {boss ? capitalizeFirstLetter(full_name) : capitalizeFirstLetter(company)}
                    </div>
                    <div className="w-40 text-center truncate">
                      {boss ? capitalizeFirstLetter(company) : capitalizeFirstLetter(full_name)}
                    </div>
                    <div className="w-40 text-center truncate">{capitalizeFirstLetter(position)}</div>
                    <div className="w-40 text-center truncate">{capitalizeFirstLetter(location)}</div>
                    <div className="w-40 text-center truncate">{review_total}</div>
                    <div className="w-40 text-center truncate">
                      <div className="flex">
                        <Rating rating={rt_avg} starClass="width-12px md:w-4 lg:w-6" />
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          }
          {user.length > 0 ?
            <>
              <div className="mb-8">
                <Pagination totalPage={pages} page={currentPage} passCurrentPage={getCurrentPage} />
              </div>
              <div className="flex justify-center">
                <Button
                  size={Button.size.EXTRA_LARGE}
                  color={Button.color.SECONDARY}
                  onClick={() => {
                    history.push(`/review?full_name=${boss ? search : ''}&company=${company ? search : ''}`);
                  }}><span>Don't see what you're looking for?</span> <br /> <span>Leave a review</span></Button>
              </div>
            </> : ''
          }
        </div>
      </section>
    </main>
  );
};
