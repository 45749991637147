export const PrivacyShortcut = ({ link, image, ...props }) => {
  const changeContent = () => {
    props.changeContent();
  }
  return (
    <a href={link}>
      <img
        onClick={changeContent}
        src={image}
        alt="terms redirect"
        className="mx-6 w-9/12 mx-auto"
      />
    </a>
  );
};
