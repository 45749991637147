import { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { scrollToPosition } from "../util/helpers";
import { Spinner } from "./Spinner";
import Modal from "react-modal";

const isMobile =
  window.innerWidth <= 800 && window.innerHeight <= 600 ? true : false;
const customStyles = {
  content: {
    background: "transparent",
    border: "none",
    padding: 0,
    marginTop: "35vh",
  },
};
export const DialogModal = ({ open, successText, route, loading }) => {
  const history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  function closeModal() {
    setIsOpen(false);
    if (route) {
      history.push(route);
      scrollToPosition(0, 0);
    }
  }

  return (
    <>
      <div
        style={{
          width: 300,
        }}
      >
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div
            style={{
              textAlign: "center",
              borderRadius: 15,
              padding: 15,
              background: "#fff",
              width: isMobile ? "100%" : "50%",
              marginLeft: isMobile ? "0%" : "25%",
            }}
          >
            {loading ? (
              <div
                style={{
                  padding: 15,
                }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
                  {successText}
                </h3>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  style={{ marginTop: 10 }}
                  onClick={closeModal}
                >
                  OK
                </button>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};
