import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export const Recaptcha = ({ ...props }) => {
    const captchaOnCHange = (e) => {
        if (e) {
            let data = {
                // secretKey: '6Let6igbAAAAAJ1bRdysX6FuS9IQ3Z_h1vfu-h4B',
                secretKey: '6Lfbvl4hAAAAAHQJbhCyKgHAhDflQHiIdcplx3bd',

                token: e
            }
            props.captchaOnCHange(data)
        }
        else {
            props.captchaOnCHange('')
        }
    }
    return (
        <>
            <ReCAPTCHA
                // sitekey={'6LcSEGcbAAAAABMZXmS2mg2YyNG7ZjzE6PEzFmLj'}
                sitekey={'6Lfbvl4hAAAAAPNqo_kypfAPRFlSa2TYXUTelaZT'}
           
                onChange={captchaOnCHange}
                onExpired={captchaOnCHange}
            />
        </>
    )
}