import { classNames } from "../util/helpers";

export const Button = ({ color, size, children, margin, custom, onClick, enabled=false, dim, width, ...props }) => {
  return (
    <button
      className={classNames("rounded-full text-white font-lato", color, size, margin, width, custom, dim)}
      onClick={onClick}
      disabled={enabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.color = {
  PRIMARY: "bg-primary",
  SECONDARY: "bg-secondary",
};

Button.size = {
  SMALL: "w-60 p-4 font-semibold lg-font-16p",
  LARGE: "w-80 p-4 font-semibold lg-font-25p",
  EXTRA_SMALL: "py-4 font-semibold text-xs lg-font-12p",
  EXTRA_LARGE: "py-4 md:px-14 font-semibold font-9p width-18"
};

Button.margin = {
  HOME: "mx-6",
  SEARCH: "mx-3"
};

Button.opacity = {
  DISABLE: "opacity-50"
}

Button.width = {
  WIDTH_48: "md:w-48 width-9  font-9p"
}

Button.review = {
  SMALL: "width-15"
}
