export const FooterIcon = ({ href, type }) => {
  return (
    <a href={href} target="_blank"  rel="noreferrer" className="text-white mx-3 xs-social lg:text-4xl">
      <i className={type}></i>
    </a>
  );
};

FooterIcon.type = {
  FACEBOOK: "fab fa-facebook-f",
  INSTAGRAM: "fab fa-instagram",
  TWITTER: "fab fa-twitter",
};