import { Title } from "./Title";

export const PrivacyCard = ({ image, text }) => {
  return (
    <div className="mb-16">
      <img
        className="w-3/12 mb-8 mt-8 mx-auto font-itc"
        src={image}
        alt="privacy card graphic"
      />
      <Title className="text-center">{text}</Title>
    </div>
  );
};
