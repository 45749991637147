import { useHistory } from "react-router-dom";
import { Title } from "../components/Title";
import "../scss/thankyou.scss";

export const Thankyou = () => {

const history = useHistory();

const HandleClick=()=>{
    history.push("/survey")
}

  return (
    <main>
      <section className="py-32">
      <img className="img-thankyou" src={"images/reviewed-thankyou.png"}  />
        <div className="xs-mx-2 md:mx-10 lg:mx-40 my-12">
        <Title className="text-center my-12 title-thank ">Thank you for submitting a Review!</Title>
        </div>
        <div className="button-div">
            <button className="rounded-full text-white font-lato bg-primary w-80 p-4 font-semibold  thankyou-btn"  onClick={HandleClick} >Submit Another Review</button>
        </div>
      </section>
    </main>
  );
};
