import { useEffect, useRef } from 'react'
import { Title } from "./Title";
import { PrivacyContentTitle } from "./PrivacyContentTitle";
import { PrivacyContentParagraph } from "./PrivacyContentParagraph";
import { scrollToPosition } from '../util/helpers';

export const PrivacyContent = ({scrollTo, count}) => {
  const privacyRef = useRef(null);
  const termsRef = useRef(null);
  
  useEffect(() => {
    if(scrollTo === 'privacy'){
      scrollToPosition(0, privacyRef.current.offsetTop - 60);
    }
    if(scrollTo === 'terms') {
      scrollToPosition(0, termsRef.current.offsetTop - 60) 
    }
  }, [scrollTo, count]);
  return (
    <section className="py-12">
      <div className="xs-mx-2 md:mx-20 lg:mx-40">
        <div id="terms" ref={termsRef}>
          <Title className="mb-2 font-itc" >Terms & Conditions</Title>
          <PrivacyContentTitle>
            Agreement between User and reviewmyboss.com
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            Welcome to reviewmyboss.com. The reviewmyboss.com website (the
            "Site") is comprised of various web pages operated by ITG LLC..
            reviewmyboss.com is offered to you conditioned on your acceptance
            without modification of the terms, conditions, and notices contained
            herein (the "Terms"). Your use of reviewmyboss.com constitutes your
            agreement to all such Terms. Please read these terms carefully, and
            keep a copy of them for your reference.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            reviewmyboss.com is a Surveys/Questionnaire Site.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            Fostering stronger, more productive relationships between leaders
            and the people on their teams
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Privacy </PrivacyContentTitle>
          <PrivacyContentParagraph>
            Your use of reviewmyboss.com is subject to ITG LLC.'s Privacy
            Policy. Please review our Privacy Policy, which also governs the
            Site and informs users of our data collection practices.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Electronic Communications</PrivacyContentTitle>
          <PrivacyContentParagraph>
            Visiting reviewmyboss.com or sending emails to ITG LLC. constitutes
            electronic communications. You consent to receive electronic
            communications and you agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communications be in writing.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Children Under Thirteen</PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. does not knowingly collect, either online or offline,
            personal information from persons under the age of thirteen. If you
            are under 18, you may use reviewmyboss.com only with permission of a
            parent or guardian.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Links to Third Party Sites/Third Party Services
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            reviewmyboss.com may contain links to other websites ("Linked
            Sites"). The Linked Sites are not under the control of ITG LLC. and
            ITG LLC. is not responsible for the contents of any Linked Site,
            including without limitation any link contained in a Linked Site, or
            any changes or updates to a Linked Site. ITG LLC. is providing these
            links to you only as a convenience, and the inclusion of any link
            does not imply endorsement by ITG LLC. of the site or any
            association with its operators.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            Certain services made available via reviewmyboss.com are delivered
            by third party sites and organizations. By using any product,
            service or functionality originating from the reviewmyboss.com
            domain, you hereby acknowledge and consent that ITG LLC. may share
            such information and data with any third party with whom ITG LLC.
            has a contractual relationship to provide the requested product,
            service or functionality on behalf of reviewmyboss.com users and
            customers.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            No Unlawful or Prohibited Use/Intellectual Property
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use reviewmyboss.com strictly in accordance with these
            terms of use. As a condition of your use of the Site, you warrant to
            ITG LLC. that you will not use the Site for any purpose that is
            unlawful or prohibited by these Terms. You may not use the Site in
            any manner which could damage, disable, overburden, or impair the
            Site or interfere with any other party's use and enjoyment of the
            Site. You may not obtain or attempt to obtain any materials or
            information through any means not intentionally made available or
            provided for through the Site.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site, is the property of ITG LLC. or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices, legends or other
            restrictions contained in any such content and will not make any
            changes thereto.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. ITG LLC. content is not for resale. Your use of the Site
            does not entitle you to make any unauthorized use of any protected
            content, and in particular you will not delete or alter any
            proprietary rights or attribution notices in any content. You will
            use protected content solely for your personal use, and will make no
            other use of the content without the express written permission of
            ITG LLC. and the copyright owner. You agree that you do not acquire
            any ownership rights in any protected content. We do not grant you
            any licenses, express or implied, to the intellectual property of
            ITG LLC. or our licensors except as expressly authorized by these
            Terms.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Use of Communication Services
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            The Site may contain bulletin board services, chat areas, news
            groups, forums, communities, personal web pages, calendars, and/or
            other message or communication facilities designed to enable you to
            communicate with the public at large or with a group (collectively,
            "Communication Services"). You agree to use the Communication
            Services only to post, send and receive messages and material that
            are proper and related to the particular Communication Service.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            By way of example, and not as a limitation, you agree that when
            using a Communication Service, you will not: defame, abuse, harass,
            stalk, threaten or otherwise violate the legal rights (such as
            rights of privacy and publicity) of others; publish, post, upload,
            distribute or disseminate any inappropriate, profane, defamatory,
            infringing, obscene, indecent or unlawful topic, name, material or
            information; upload files that contain software or other material
            protected by intellectual property laws (or by rights of privacy of
            publicity) unless you own or control the rights thereto or have
            received all necessary consents; upload files that contain viruses,
            corrupted files, or any other similar software or programs that may
            damage the operation of another's computer; advertise or offer to
            sell or buy any goods or services for any business purpose, unless
            such Communication Service specifically allows such messages;
            conduct or forward surveys, contests, pyramid schemes or chain
            letters; download any file posted by another user of a Communication
            Service that you know, or reasonably should know, cannot be legally
            distributed in such manner; falsify or delete any author
            attributions, legal or other proper notices or proprietary
            designations or labels of the origin or source of software or other
            material contained in a file that is uploaded; restrict or inhibit
            any other user from using and enjoying the Communication Services;
            violate any code of conduct or other guidelines which may be
            applicable for any particular Communication Service; harvest or
            otherwise collect information about others, including e-mail
            addresses, without their consent; violate any applicable laws or
            regulations.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            ITG LLC. has no obligation to monitor the Communication Services.
            However, ITG LLC. reserves the right to review materials posted to a
            Communication Service and to remove any materials in its sole
            discretion. ITG LLC. reserves the right to terminate your access to
            any or all of the Communication Services at any time without notice
            for any reason whatsoever.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            ITG LLC. reserves the right at all times to disclose any information
            as necessary to satisfy any applicable law, regulation, legal
            process or governmental request, or to edit, refuse to post or to
            remove any information or materials, in whole or in part, in ITG
            LLC.'s sole discretion.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            Always use caution when giving out any personally identifying
            information about yourself or your children in any Communication
            Service. ITG LLC. does not control or endorse the content, messages
            or information found in any Communication Service and, therefore,
            ITG LLC. specifically disclaims any liability with regard to the
            Communication Services and any actions resulting from your
            participation in any Communication Service. Managers and hosts are
            not authorized ITG LLC. spokespersons, and their views do not
            necessarily reflect those of ITG LLC..
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            Materials uploaded to a Communication Service may be subject to
            posted limitations on usage, reproduction and/or dissemination. You
            are responsible for adhering to such limitations if you upload the
            materials.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Materials Provided to reviewmyboss.com or Posted on Any ITG LLC. Web
            Page
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. does not claim ownership of the materials you provide to
            reviewmyboss.com (including feedback and suggestions) or post,
            upload, input or submit to any ITG LLC. Site or our associated
            services (collectively "Submissions"). However, by posting,
            uploading, inputting, providing or submitting your Submission you
            are granting ITG LLC., our affiliated companies and necessary
            sublicensees permission to use your Submission in connection with
            the operation of their Internet businesses including, without
            limitation, the rights to: copy, distribute, transmit, publicly
            display, publicly perform, reproduce, edit, translate and reformat
            your Submission; and to publish your name in connection with your
            Submission.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            No compensation will be paid with respect to the use of your
            Submission, as provided herein. ITG LLC. is under no obligation to
            post or use any Submission you may provide and may remove any
            Submission at any time in ITG LLC.'s sole discretion.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            By posting, uploading, inputting, providing or submitting your
            Submission you warrant and represent that you own or otherwise
            control all of the rights to your Submission as described in this
            section including, without limitation, all the rights necessary for
            you to provide, post, upload, input or submit the Submissions.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>International Users</PrivacyContentTitle>
          <PrivacyContentParagraph>
            The Service is controlled, operated and administered by ITG LLC.
            from our offices within the USA. If you access the Service from a
            location outside the USA, you are responsible for compliance with
            all local laws. You agree that you will not use the ITG LLC. Content
            accessed through reviewmyboss.com in any country or in any manner
            prohibited by any applicable laws, restrictions or regulations.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Indemnification </PrivacyContentTitle>
          <PrivacyContentParagraph>
            You agree to indemnify, defend and hold harmless ITG LLC., its
            officers, directors, employees, agents and third parties, for any
            losses, costs, liabilities and expenses (including reasonable
            attorney's fees) relating to or arising out of your use of or
            inability to use the Site or services, any user postings made by
            you, your violation of any terms of this Agreement or your violation
            of any rights of a third party, or your violation of any applicable
            laws, rules or regulations. ITG LLC. reserves the right, at its own
            cost, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will
            fully cooperate with ITG LLC. in asserting any available defenses.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Class Action Waiver</PrivacyContentTitle>
          <PrivacyContentParagraph>
            Any arbitration under these Terms and Conditions will take place on
            an individual basis; class arbitrations and
            class/representative/collective actions are not permitted. THE
            PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE
            PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION
            AGAINST THE OTHER. Further, unless both you and ITG LLC. agree
            otherwise, the arbitrator may not consolidate more than one person's
            claims, and may not otherwise preside over any form of a
            representative or class proceeding.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Liability Disclaimer</PrivacyContentTitle>
          <PrivacyContentParagraph>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            ITG LLC. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES
            IN THE SITE AT ANY TIME.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            ITG LLC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
            SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
            THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
            CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
            WARRANTY OR CONDITION OF ANY KIND. ITG LLC. AND/OR ITS SUPPLIERS
            HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
            INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            ITG LLC. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
            PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
            WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
            DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
            OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
            SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
            SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
            OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY OR OTHERWISE, EVEN IF ITG LLC. OR ANY OF ITS
            SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
            SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
            OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
            PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Termination/Access Restriction
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. reserves the right, in its sole discretion, to terminate
            your access to the Site and the related services or any portion
            thereof at any time, without notice. To the maximum extent permitted
            by law, this agreement is governed by the laws of the State of
            Wyoming and you hereby consent to the exclusive jurisdiction and
            venue of courts in Wyoming in all disputes arising out of or
            relating to the use of the Site. Use of the Site is unauthorized in
            any jurisdiction that does not give effect to all provisions of
            these Terms, including, without limitation, this section.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and ITG LLC. as a result of this
            agreement or use of the Site. ITG LLC.'s performance of this
            agreement is subject to existing laws and legal process, and nothing
            contained in this agreement is in derogation of ITG LLC.'s right to
            comply with governmental, court and law enforcement requests or
            requirements relating to your use of the Site or information
            provided to or gathered by ITG LLC. with respect to such use. If any
            part of this agreement is determined to be invalid or unenforceable
            pursuant to applicable law including, but not limited to, the
            warranty disclaimers and liability limitations set forth above, then
            the invalid or unenforceable provision will be deemed superseded by
            a valid, enforceable provision that most closely matches the intent
            of the original provision and the remainder of the agreement shall
            continue in effect.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and ITG LLC. with respect to the
            Site and it supersedes all prior or contemporaneous communications
            and proposals, whether electronic, oral or written, between the user
            and ITG LLC. with respect to the Site. A printed version of this
            agreement and of any notice given in electronic form shall be
            admissible in judicial or administrative proceedings based upon or
            relating to this agreement to the same extent and subject to the
            same conditions as other business documents and records originally
            generated and maintained in printed form. It is the express wish to
            the parties that this agreement and all related documents be written
            in English.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Changes to Terms </PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. reserves the right, in its sole discretion, to change the
            Terms under which reviewmyboss.com is offered. The most current
            version of the Terms will supersede all previous versions. ITG LLC.
            encourages you to periodically review the Terms to stay informed of
            our updates.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            <i>Effective as of February 26, 2021</i>
          </PrivacyContentParagraph>
        </div>
        <div id="privacy" ref={privacyRef}>
          <Title className="mb-2">Privacy Policy</Title>
          <PrivacyContentParagraph>
            Protecting your private information is our priority. This Statement
            of Privacy applies to www.reviewmyboss.com, and ITG LLC. and governs
            data collection and usage. For the purposes of this Privacy Policy,
            unless otherwise noted, all references to ITG LLC. includes
            www.reviewmyboss.com. The ITG LLC. website is a fostering stronger,
            more productive relationships between leaders and the people on
            their teams site. By using the ITG LLC. website, you consent to the
            data practices described in this statement.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Collection of your Personal Information
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            Please keep in mind that if you directly disclose personally
            identifiable information or personally sensitive data through ITG
            LLC.'s public review platform, this information may be collected and
            used by others.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            We do not collect any personal information about you unless you
            voluntarily provide it to us. However, you may be required to
            provide certain personal information to us when you elect to use
            certain products or services. These may include: (a) registering for
            an account; (b) entering a sweepstakes or contest sponsored by us or
            one of our partners; (c) signing up for special offers from selected
            third parties; (d) sending us an email message; (e) submitting your
            credit card or other payment information when ordering and
            purchasing products and services. To wit, we will use your
            information for, but not limited to, communicating with you in
            relation to services and/or products you have requested from us. We
            also may gather additional personal or non-personal information in
            the future.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Sharing Information with Third Parties
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. does not sell, rent or lease its customer lists to third
            parties.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            ITG LLC. may share data with trusted partners to help perform
            statistical analysis, send you email or postal mail, provide
            customer support, or arrange for deliveries. All such third parties
            are prohibited from using your personal information except to
            provide these services to ITG LLC., and they are required to
            maintain the confidentiality of your information.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            ITG LLC. may disclose your personal information, without notice, if
            required to do so by law or in the good faith belief that such
            action is necessary to: (a) conform to the edicts of the law or
            comply with legal process served on ITG LLC. or the site; (b)
            protect and defend the rights or property of ITG LLC.; and/or (c)
            act under exigent circumstances to protect the personal safety of
            users of ITG LLC., or the public.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Automatically Collected Information
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            Information about your computer hardware and software may be
            automatically collected by ITG LLC.. This information can include:
            your IP address, browser type, domain names, access times and
            referring website addresses. This information is used for the
            operation of the service, to maintain quality of the service, and to
            provide general statistics regarding use of the ITG LLC. website.
            ITC LLC. reserves the right to delete server logs at our discretion.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Links </PrivacyContentTitle>
          <PrivacyContentParagraph>
            This website contains links to other sites. Please be aware that we
            are not responsible for the content or privacy practices of such
            other sites. We encourage our users to be aware when they leave our
            site and to read the privacy statements of any other site that
            collects personally identifiable information.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>
            Security of your Personal Information
          </PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. secures your personal information from unauthorized access,
            use, or disclosure. ITG LLC. uses the following methods for this
            purpose:
          </PrivacyContentParagraph>
          <PrivacyContentTitle>SSL Protocol </PrivacyContentTitle>
          <PrivacyContentParagraph>
            When personal information (such as a credit card number) is
            transmitted to other websites, it is protected through the use of
            encryption, such as the Secure Sockets Layer (SSL) protocol.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            We strive to take appropriate security measures to protect against
            unauthorized access to or alteration of your personal information.
            Unfortunately, no data transmission over the Internet or any
            wireless network can be guaranteed to be 100% secure. As a result,
            while we strive to protect your personal information, you
            acknowledge that: (a) there are security and privacy limitations
            inherent to the Internet which are beyond our control; and (b)
            security, integrity, and privacy of any and all information and data
            exchanged between you and us through this Site cannot be guaranteed.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Right to Deletion </PrivacyContentTitle>
          <PrivacyContentParagraph>
            Please note that we may not be able to comply with requests to
            delete your personal information if it is necessary to:
            <ul>
              <li>
                • Complete the transaction for which the personal information
                was collected, fulfill the terms of a written warranty or
                product recall conducted in accordance with federal law, provide
                a good or service requested by you, or reasonably anticipated
                within the context of our ongoing business relationship with
                you, or otherwise perform a contract between you and us;
              </li>
              <li>
                • Detect security incidents, protect against malicious,
                deceptive, fraudulent, or illegal activity; or prosecute those
                responsible for that activity;
              </li>
              <li>
                • Debug to identify and repair errors that impair existing
                intended functionality;
              </li>
              <li>
                • Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law;
              </li>
              <li>
                • Comply with the California Electronic Communications Privacy
                Act;
              </li>
              <li>
                • Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </li>
              <li>
                • Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;
              </li>
              <li>• Comply with an existing legal obligation; or</li>
              <li>
                • Otherwise use your personal information, internally, in a
                lawful manner that is compatible with the context in which you
                provided the information.
              </li>
            </ul>
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Children Under Thirteen</PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. does not knowingly collect personally identifiable
            information from children under the age of thirteen. If you are
            under the age of thirteen, you must ask your parent or guardian for
            permission to use this website.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>E-mail Communications</PrivacyContentTitle>
          <PrivacyContentParagraph>
            From time to time, ITG LLC. may contact you via email for the
            purpose of providing announcements, promotional offers, alerts,
            confirmations, surveys, and/or other general communication.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            If you would like to stop receiving marketing or promotional
            communications via email from ITG LLC., you may opt out of such
            communications by clicking on the UNSUBSCRIBE button.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>External Data Storage Sites</PrivacyContentTitle>
          <PrivacyContentParagraph>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted.
          </PrivacyContentParagraph>
          <PrivacyContentTitle>Changes to this Statement</PrivacyContentTitle>
          <PrivacyContentParagraph>
            ITG LLC. reserves the right to change this Privacy Policy from time
            to time. We will notify you about significant changes in the way we
            treat personal information by sending a notice to the primary email
            address specified in your account, by placing a prominent notice on
            our website, and/or by updating any privacy information. Your
            continued use of the website and/or Services available after such
            modifications will constitute your: (a) acknowledgment of the
            modified Privacy Policy; and (b) agreement to abide and be bound by
            that Policy.
          </PrivacyContentParagraph>
          <PrivacyContentParagraph>
            Effective as of February 26, 2021
          </PrivacyContentParagraph>
        </div>
      </div>
    </section>
  );
};
