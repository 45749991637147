import { useEffect, useState} from "react";
import {useQuery, classNames, percentage,capitalize } from "../util/helpers";
import CustomInput from "../components/CustomInput";
import ProgressBar from "../components/ProgressBar";
import { questions } from "../constants/questions";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "../scss/survey.scss";

import { Recaptcha } from "../components/Recaptcha";
 

export const Survey = () => {
  const [questionNo, setQuestionNo] = useState(1);
  const [questionData, setQuestionData] = useState({});
  const [ input, setInput] = useState({});
  const [data, setData] = useState({});
  const { handleSubmit,formState: { errors }, setValue } = useForm({});
  const [enabled,setEnabled] = useState(false);
  const [citylist, setCitylist] = useState([]);
  const [companylist, setCompanylist] = useState([]);
  const [cursor, setCursor] = useState(-1);
  
  const[errorLocation,setErrorLocation]=useState("");
  const[rating,setRating]=useState({})
  const[multiple,setMultiple]=useState(false);
  const query = useQuery();
  const[error,setError]=useState("")

  
  const [verified, setVerified] = useState("");
   

  useEffect(() => {
    if(query.get("full_name")||query.get("position")||query.get("company")||query.get("location")){
    var object={full_name:query.get("full_name"),position:query.get("position"),company:query.get("company"),location:query.get("location")}
    setData(object);

    }
   },[]);
  
   
const buttonHandler = () => {
       if(questionData.position===0){
         setQuestionData("");
         }
       else{
        setQuestionNo(questionNo - 1);
       getQuestion(questionData.position,"back")
     }
    };

  const history = useHistory();
  const getQuestion = (position, type)=>{
 
     if(type === "next"){
      let question = questions.question_bank.filter((question) => question.position === position);
      setQuestionData(question[0]);
       }else if(type === "back"){
      let question = questions.question_bank.filter((question) => {
       if(question && question.choices){
          let data = question.choices.filter((choice)=>choice.branch === position)
          if(data.length > 0){return question}
        }
        return null;
      });
      setQuestionData(question[0]);
     }
  }
  const nexthandler=()=>{
    let answer;
      if(Object.keys(questionData).length===0){
        let question = questions.question_bank.filter((question) => question.position === 0);
          setQuestionData(question[0]);
           }else{
        if(input[questionData.position]){
         if(questionData&&questionData?.family==="multiple_choice"){
          answer = questionData?.choices.filter((choice)=>choice.text == Object.keys(input[questionData.position])[0])[0];
          setMultiple(true)
      }else{
          answer = questionData?.choices.filter((choice)=>choice.text == input[questionData.position])[0];

      }
        }else{
          answer = questionData?.choices[0]
        }
        getQuestion(answer.branch,"next");
        setQuestionNo(questionNo + 1);
        
      }
     
  }
  const multipleChoice=(e)=>{
    setInput({...input,[questionData.position]:{...input[questionData.position],[e.target.name]:e.target.checked}})
    setMultiple(false);
   }

  const handleInputChange = (e) =>{
  
     setInput({...input,[questionData.position]:e.target.value});
     setRating({...rating,[questionData.position]: e.target.value});
     if(Object.keys(input).length>0){
  
     setInput({...input,[questionData.position]:e.target.value});

    }
  }
  const handleOnPress = (value) =>{
 
     setRating({...rating,[questionData.position]:value});
     setInput({...input,[questionData.position]:value});
    }
 
const selectSuggesstion = (key, value) => {
  setCursor(-1);
  if(key === 'location') {
    
  } 
  setValue(key, value)
  setData({ ...data, [key]: value });
  emptyList();
}

const onSubmit = () => {
 
  var errorobject={full_name:"",position:"",company:"",location:""}
  var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
  if (!regName.test(data.full_name)){
    errorobject.full_name="First name & Last name is required"
  }
  else{
    setError({...error,"full_name":""});
  }

  if(!data.position){
    errorobject.position="Position is required"
  }
  else{
    setError({...error,"position":""});
  }

  if(!data.company){
    errorobject.company="Company name is required "
  }
  else{
    setError({...error,"company":""});
  }

  if(!data.location){
    errorobject.location="location name is required "
  }
  else{
    setError({...error,"location":""});
  }
  setError(errorobject);

  }

useEffect(()=>{
  if(
  Object.keys(data).length>=4&&
  Object.keys(errorLocation).length===0&&
  Object.keys(error.full_name).length===0
  )
  {
     nexthandler();
   }
},[errors]);

const onKeyDownEvent = (event, type) => {
  if(event.key === 'ArrowDown') {
    setCursor((c => (type === 'company' ? c < companylist.length - 1 : c < citylist.length -1)  ? c + 1 : c))
  }
  if(event.key === 'ArrowUp') {
    setCursor(c => (c > 0 ? c - 1 : 0))
  }
    if(event.key === 'Enter') {
      if(cursor > -1) {
        if(type === 'location') {
          
        } 
        const value = type === 'company' ? capitalize(companylist[cursor]?.name) : capitalize(citylist[cursor]?.city) + ', ' + citylist[cursor]?.state_id;
        setValue(type, value)
        setData({ ...data, [type]: value });
        setCursor(-1);
        emptyList();
      }
    }
}


const onFocusChange = () => {
  setCursor(-1);
  emptyList();
}

const emptyList = () => {
  setCitylist([]);
  setCompanylist([]);
}

async function postAPIData (apiData) {


  const response = await fetch(
    'https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/submit-review/',
    {
      method: 'POST',
      body : JSON.stringify(apiData),
    }
  );

  if (!response.ok) {
    console.log(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  console.log("API Response",data);

  if (data === "Review Submitted")
{
  history.push("/thankyou")
}

 
}
 


const HandleClick=()=>{
 

  let apiData = data;
  apiData.cp=verified;

  let data2= [];
  let index = 1;

  for (let data1 in input)
  {
    if(typeof(input[data1])==="object")
    {
      data2+=`"question_${index}":`
        for(let y in input[data1])
        {
         data2+=`"${y}",`
                }
    } else {
    data2+=`"question_${index}":`;
    data2+=`"${input[data1]}",`;
     }
   index++;
  }


Object.assign(apiData,{"questions":data2})
   
   
    let ratingValues=[];
for (let entry in input){
  
  if ((entry === "111")||(entry === "333"))
{
  ratingValues.push(input[entry]) 
}
}

let count =0;
ratingValues.map((value)=>(count+=parseInt(value)))
console.log("rating values",ratingValues)


apiData.rating_overall=(count/ratingValues.length).toString();
console.log("@$# API data => ",apiData)

  postAPIData(apiData);
     

}
const handleInputChanges=(e)=>{
  if(e.target.value !== ""){
  setData({ ...data, [e.target.name]: e.target.value });
  setError({...error,[e.target.name]:""});
  }else{
    setData({ ...data, [e.target.name]: e.target.value });
  }

  if (e.target.name === 'company') {
    setData({ ...data, [e.target.name]: e.target.value });
    setCitylist([]);
    fetch(
      `https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/company?company=${e.target.value.replaceAll('&','%26')}`
    )
      .then((response) => response.json())
      .then((res) => {
        if(e.target.value.length === 0) {
          setCompanylist([]);
        } else {
          setCompanylist(res);
        }
      }); 
  } else {
    emptyList();
    setData({ ...data, [e.target.name]: e.target.value });
  }
  if (e.target.name === 'term') {
    setCitylist([]);
    setData({ ...data, [e.target.name]: e.target.checked });
  } else if (e.target.name === 'location'){
   
    if(e.target.value.length > 0) {
      fetch(
        `https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/city?city=${e.target.value}`
      )
        .then((response) => response.json())
        .then((res) => {
          if(e.target.value.length === 0) {
            setCitylist([]);
          } else {
            setCitylist(res);
            console.log("res",res)
            if(res.length>0){
              setErrorLocation("");
            }else{
             setErrorLocation("Please select a valid Location from dropdown");
            }
           }
        });
       
    }
    else {
            setCitylist([]);
          }
  }

}

async function  captchaOnCHange (data) {
  if (data && data.token) {
    setVerified(data.token);
 setEnabled(true)
  } else {
    setVerified('');
  }
}
 
  return (
    <main> 
      <section className="py-20 h-85">
        <div className="xs-mx-2 md:mx-10 lg:mx-52 mar-top-3">
          {Object.keys(questionData).length >0  &&<div>
            <div className="pad-10 Question-bold">
              Question {questionNo} / {questions.total_questions}
            </div>
            <ProgressBar
              bgcolor="#DB7825"
              progress={percentage(questionNo, questions.total_questions)}
            />
          </div>}
          {Object.keys(questionData).length ===0  &&
        <div>
          <div> <h1 className=" text-primary font-bold form-head">Boss Details</h1></div>
          <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}>
         <section className="py-20 form-padd">
          <div className="md:mx-10 "> 
            <div className="grid grid-cols-2 gap-4 mb-10 grid-block">
              <div>
                <input
                  autoComplete="off"
                  onFocusCapture={onFocusChange}
                  onChangeCapture={(e)=>handleInputChanges(e)}
                  name="full_name"
                  value={data.full_name}
                  className="border border-black rounded-xl font-14p lg-font-21p p-4 w-full outline-none mb-5"
                  placeholder="Boss' First Name & Last Name"
                 />
                {error&&error?.full_name&&(
                  <small className="mb-3 text-normal text-red-500">{error.full_name}</small>
                )}
               
              </div>
              <div>
                <input
                  autoComplete="off"
                  onFocusCapture={onFocusChange}
                  onChangeCapture={(e)=>handleInputChanges(e)}
                  name="position"
                  value={data.position}
                  className="border border-black rounded-xl font-14p lg-font-21p p-4 w-full outline-none mb-5"
                  placeholder="Boss' Position"
                 />
                 {error&&error?.position&&(
                  <small className="mb-3 text-normal text-red-500">{error.position}</small>
                )}
                
              </div>
              <div>
                <input
                  autoComplete="off"
                  onChangeCapture={handleInputChanges}
                  onFocusCapture={onFocusChange}
                  onKeyDown={(e) => onKeyDownEvent(e, 'company')}
                  name="company"
                  value={data.company}
                  className="border border-black rounded-xl font-14p lg-font-21p p-4 w-full outline-none"
                  placeholder="Company Name"
                 />

                
                <div className="dropdown">
                  <div className="dropdown-content">
                    {companylist.length > 0 && companylist.map((item, i) => {
                      return (
                        <span className={`${cursor === i ? "keyboard": ""}`} key={i} onClick={() => selectSuggesstion('company', capitalize(item.name))}>{capitalize(item.name)}</span>
                      )
                    })}
                  </div>
                </div>
                {error&&error?.company&&(
                  <small className="mb-3 text-normal text-red-500">{error.company}</small>
                )}
                
              </div>
              <div>
                <input
                  autoComplete="off"
                  onFocusCapture={onFocusChange}
                  onChangeCapture={handleInputChanges}
                  onKeyDown={(e) => onKeyDownEvent(e, 'location')}
                  name="location"
                  value={data.location}
                  className="border border-black rounded-xl font-14p lg-font-21p p-4 w-full outline-none"
                  placeholder="Company Location"
                 />
                <div className="dropdown">
                  <div className="dropdown-content">
                   {citylist.length >0 && citylist.map((item, i) => {
                      return (
                        <span className={`${cursor === i ? "keyboard": ""}`} key={i} onClick={() => selectSuggesstion('location', capitalize(item.city) + ', ' + item.state_id)}>{capitalize(item.city)}, {item.state_id}</span>
                      )
                    })}
                  </div>
                </div>
                {error&&error?.location&&(
                  <small className="mb-3 text-normal text-red-500">{error.location}</small>
                )}
  
                {!error.location &&(
                    <div className="mb-3 text-normal text-red-500">
                  { errorLocation}
                   </div>
                   )}

              </div>
            </div>
           <div className="flex justify-center">
             <div>
              <div>
                <button  className={classNames("btn-next border-rad desk",Object.keys(data).length===4 && "btn-dis",Object.keys(data).length !==4 && "btn-disable")}
                  onClick={onSubmit}
                >{`Next>>`}</button>
              </div>
              <div>
              <button  className={classNames("btn-next border-rad mobile",Object.keys(data).length===4 && "btn-dis",Object.keys(data).length !==4 && "btn-disable")}
                onClick={onSubmit}
              >{`Next`}</button>
            </div>
            </div>
              </div>
          </div>
        </section>
    
      </form>
      </div>}
          <div className="q-margin">
            <label className="lg-font-22p text-primary font-semibold">
              {questionData && questionData.headings && questionData?.headings[0].heading}
            </label>
          </div>
          {questionData && questionData?.family === "single_choice" && (
            <div className="mtb-2">
              {questionData.choices &&
                questionData.choices.length > 0 &&
                questionData.choices.map((choice, index) => (
                  
                  <div key={index}>
                    <div className="desk mtb-1">
                      <CustomInput
                        type="radio"
                        className="radio-size"
                        value={choice.text}
                        onChange={(e) =>
                          handleInputChange(e)
                        }
                        checked={input[questionData.position] === choice.text}
                      />
                      <label className="ml-15 lg-font-15p font-avenir-heavy radio-text">
                        {choice.text}
                      </label>
                    </div>
                    
                    <div className={classNames("mobile" , input[questionData.position] === choice.text && "position-rel")}>
                      <CustomInput
                        type="text"
                        value={choice.text}
                        readOnly={true} 
                        className={classNames(
                          "border border-black rounded-xl p-4 w-full outline-none mt-2 mb-8 lg-font-15p font-avenir-heavy",
                          input [ questionData.position] === choice.text  && "selected-option "
                      )}
                        onClick={(e) =>
                          handleInputChange(e)
                        }
                      />  
                      <div className={classNames("check-bg  ",  input [ questionData.position] !== choice.text && "check-none ")} >
                       <img className="check-img  " src={"images/check-orange.svg"}  />
                      </div>
                    </div> 

                   </div>
                 ))}
                </div>
          )}
          
          {questionData && questionData?.family === "drop_down" && (
            <div className="mtb-2">
              {console.log("errorfixing drop down----",input[questionData.position],input)}
                  <select  className="drop-select"  value={input[questionData.position]} onChange={(e)=>handleInputChange(e)} > 
                  <option disabled selected hidden value={""} >--Select--</option>
                   { questionData.choices&& questionData.choices.length>0&& questionData.choices.map((choices,choice) => 
                
                   <option value={choices.text} >{choices.text}</option>
                     )
                    } 
                   </select>
                   
            </div>
                 )}
                 
      {questionData && questionData?.family === "multiple_choice" && (
            <div className="mtb-2">
             {questionData.choices &&
                questionData.choices.length > 0 &&
                questionData.choices.map((choice, index) => (
                  <div key={index}>
                    <div className="mtb-1">
                      <CustomInput
                        type="checkbox"
                        className="multiple-input"
                        onChange={(e) =>
                          multipleChoice(e)
                        }
                        value={choice.text}
                        
                        checked={input[questionData.position]&&input[questionData.position][choice.text]}
                        name={choice.text}
                      
                       
                      />{console.log("questionData.positionmultiple",questionData.position,input[questionData.position],input)}
                      <label className="ml-15 lg-font-15p font-avenir-heavy multiple-label">
                        {choice.text}
                      </label>
                    </div>
                  
                   </div>
                 ))
                 }
                   
                </div>
                
          )
          }

          {questionData && questionData?.family === "rating" && (
            <div className="slider_parent box-w mtb-3">
              { questionData.choices  && questionData.choices.map((choice,index)=>(
              <img key={index} src={input[questionData.position] >= choice.text ? "images/FullStar.svg":"images/WhiteStar.svg"} alt="star" className={"w-8"} style={{height: 35}} onClick={()=>handleInputChange({target:{value:choice.text}})} />
              ))}
            </div>  
          )}

           {questionData && questionData?.family === "short_response" && (
            <CustomInput
              type="text"
              value={input[questionData.position]}
              className={
                "border border-black rounded-xl p-4 w-full outline-none mb-8 lg-font-15p font-avenir-heavy box-w mtb-1"
              }
              onChange={(e) => handleInputChange(e)}
              placeholder="Your answer"
            />
          )}
           {questionData && questionData?.family === "short_responses" && (
            <CustomInput
              type="text"
              value={input[questionData.position]}
              className={
                "border border-black rounded-xl p-4 w-full outline-none mb-8 lg-font-15p font-avenir-heavy box-w mtb-1"
              }
              onChange={(e) => handleInputChange(e)}
              placeholder="Your answer"
            />
          )}
          
          {questionData.choices && questionData?.family === "slider" && (
            
             <div className=" slider-margin">
             <input type="range"
               style={{height: 17}}
               min="1" max="5"
               name="rating[questionData.position]"
               value={rating[questionData.position]}
               className="slider"
               onChange={(e) => handleInputChange(e)}
              />
            
             <div className="grid grid-cols-5">
               <div className="row" onClick={() =>handleOnPress( "1")}>
                 <div className="v"></div>
                 <div className="lowest_rating_1 w-20 text-center font-14p lg-font-21p font-lato-regular">1</div>
               </div>
               <div className="row"  onClick={() => handleOnPress( "2")}>
                 <div className="v2"></div>
                 <div className='vn2 lg-font-21p'>2</div>
               </div>
               <div className="row" onClick={() => handleOnPress("3")}>
                 <div className="v3"></div>
                 <div className="three lg-font-21p">3</div>
               </div>
               <div className="row" onClick={() => handleOnPress( "4")}>
                 <div className="v4"></div>
                 <div className="four lg-font-21p">4</div>
               </div>
               <div className="row" onClick={() => handleOnPress( "5")}>
               <div className="v5"></div>
                 <span className="highest_rating_1 float-right width-5 md:w-20 lg:w-24 text-center font-14p lg-font-21p font-lato-regular">5</span>
               </div>
             </div>
           </div>
          
       
             )}


{questionData&&(( questionData?.position === 11 )||questionData?.position === 1 )&&     
<div className="flex justify-center mb-8">
          <Recaptcha captchaOnCHange={captchaOnCHange} />
        </div>}



          {Object.keys(questionData).length !==0  &&<div className="flex btn-align mt-20">

        
            <div>
              <div>
                <button 
                  className="btn-next border-rad desk"
                  onClick={() => buttonHandler("back")}
                >{`<<Back`}</button>
              </div>
              <div >
              
                <button 
                  className="mobile btn-back"
                    onClick={()=>buttonHandler("back")}
                    ><span>&#10229;</span>{" "}{`  Back`}</button>
              </div>
                </div>
         {questionData && questionData?.position === 1 ? (
          
              <div>
                  {/* <button enabled={enabled}  className={classNames("btn-next border-rad",!input[questionData.position] && "btn-disable")} onClick={()=>HandleClick()}>
                Submit */}
              {/* <button disabled={!input[questionData.position]}  className={classNames("btn-next border-rad",!input[questionData.position] && "btn-disable")} onClick={()=>HandleClick()}> */}
             
              <button    disabled={!enabled}  className={classNames("btn-next border-rad",!enabled && "btn-disable")} onClick={()=>HandleClick()}>
                Submit
              </button>
             </div>
            ):(
              <div>

              <div>
                <button
                  disabled={(!input[questionData.position])||(questionData?.family === "multiple_choice"&& input[questionData.position]?.Capuchin===false&&input[questionData.position]?.Mandrill===false)}
                  className={classNames("btn-next border-rad desk",!input[questionData.position]&& "btn-disable",questionData?.position === 11 &&"position-11",input[questionData.position]?.Capuchin===false&&input[questionData.position]?.Mandrill===false&&"btn-disable")}
                  onClick={() => nexthandler("next")}
               >{`Next>>`}</button>
              </div>
              <div>
              <button 
               disabled={!input[questionData.position]||(questionData?.family === "multiple_choice"&& input[questionData.position]?.Capuchin===false &&input[questionData.position]?.Mandrill===false)}
                className={classNames("btn-next border-rad mobile",!input[questionData.position]&& "btn-disable",questionData?.position === 11 &&"position-11",input[questionData.position]?.Capuchin===false&&input[questionData.position]?.Mandrill===false&&"btn-disable")}
                onClick={() => nexthandler("next")}
              >{`Next`}</button>
            </div>
            </div>
            )}
          
               {questionData&& questionData?.position === 11 &&           
              <div>
              {/* <button  disabled={!input[questionData.position]}  className={classNames("btn-next border-rad",!input[questionData.position] && "btn-disable")} onClick={()=>HandleClick()}> */}
               
              <button disabled={!enabled} className={classNames("btn-next border-rad",!enabled&& "btn-disable")} onClick={()=>HandleClick()}>
                Submit
              </button>
             </div>
          
              }
          </div>}
        </div>
      </section>
    </main>
  );
};
