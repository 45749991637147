import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import { scrollToPosition } from '../util/helpers';

export const Reviewed = () => {
  const history = useHistory();

  useEffect(() => {
    scrollToPosition(0,0);
  }, [])

  return (
    <main>
      <section className="py-32">
        <div className="md:mx-20 lg:mx-40">
          <img
            src="images/reviewed-thankyou.png"
            className="mx-auto w-1/2 my-12"
            alt="thankyou"
          />
          <h4 className="text-primary text-3xl font-bold text-center mb-20">
            Thank you for submitting a review!
          </h4>
          <div className="flex justify-center">
            <Button
              size={Button.size.LARGE}
              color={Button.color.PRIMARY}
              onClick={() => {
                history.push("/review");
              }}
            >
              Submit Another Review
            </Button>
          </div>
        </div>
      </section>
    </main>
  );
};
