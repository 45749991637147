import { useState } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import { Review } from "./pages/Review";
import { Privacy } from "./pages/Privacy";
import { ContactUs } from "./pages/ContactUs";
import { Results } from "./pages/Results";
import { Profile } from "./pages/Profile";
import { Reviewed } from "./pages/Reviewed";
import { Survey } from "./pages/Survey";
import NavBar from "./components/NavBar";
import { Footer } from "./components/Footer";
import { Thankyou } from './pages/Thankyou';
import { Searchsub } from './components/Searchsub';

const App = () => {
  const [show, setShow] = useState(0);

  const onRouteChange = () => {
    setShow((data) => data + 1)
  }
  return (
    <div onClick={onRouteChange} className="mh-100 flex fd-col justify-space-between">
      <NavBar count={show} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/review" exact component={Review} />
        <Route path="/privacy" exact component={Privacy} />
        <Route path="/contactus" exact component={ContactUs} />
        <Route path="/results" exact component={Results} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/reviewed" exact component={Reviewed} />
        <Route path="/survey" exact component={Survey} />
        <Route path="/thankyou" exact component={Thankyou} />
        <Route path="/searchsub" exact component={Searchsub} />
        <Route path='*' render={() => <Redirect to='/' />} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
