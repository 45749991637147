export const Rating = ({ rating, starClass }) => {
    if (rating > 5) {
        rating = 5
    }
    return (
        <>
            {Number(Math.floor(rating)) > 0 &&
                Array(Number(Math.floor(rating))).fill().map((_, i) => {
                    return (
                        <img
                            key={i}
                            src="images/FullStar.svg"
                            alt="star"
                            className={starClass}
                            style={{height: 35}}
                        />
                    )
                })
            }
            {Number(rating % 1).toFixed(2) > 0 &&
                <img
                    src={Number(rating % 1).toFixed(2) > 0.69 ? 'images/FullStar.svg' : Number(rating % 1).toFixed(2) > 0.29 ? 'images/HalfStar.svg' : 'images/WhiteStar.svg'}
                    alt="star"
                    className={starClass}
                    style={{height: 35}}
                /> 
            }
            {Math.floor(5 - Number(rating)) > 0 &&
                Array((Math.floor(5 - Number(rating)))).fill().map((_, i) => {
                    return (
                        <img
                            key={i}
                            src="images/WhiteStar.svg"
                            alt="star"
                            className={starClass}
                            style={{height: 35}}
                        />
                    )
                })
            }
        </>
    )
}