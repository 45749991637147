import { useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { Blurb } from "../components/Blurb";
import { HomeStep } from "../components/HomeStep";
import { HomeLeaderboard } from "../components/HomeLeaderboard";
import { Search } from "../components/Search";
import { scrollToPosition } from '../util/helpers';

const Home = () => {
  const [work, setWork] = useState(false);
  const [goodboss, setGoodboss] = useState(false);
  const [badboss, setBadboss] = useState(false);
  const [count, setCount] = useState(0)
  const history = useHistory();
  const badRef = useRef(null);
  const goodRef = useRef(null);
  const clickEvent = (e) => {
    if (e === 'work') {
      setWork(!work);
      setGoodboss(false);
      setBadboss(false);
    }
    if (e === 'goodboss') {
      scrollToPosition(0, 480)
      setGoodboss(!goodboss);
      setWork(false);
      setBadboss(false);
    }
    if (e === 'badboss') {
      scrollToPosition(0, 560)
      setBadboss(!badboss);
      setWork(false);
      setGoodboss(false);
    }
    if (e === 'review') {
      history.push('/review');
    }
  }

  const onHide = () => {
    setCount((data) => data + 1)
  }

  const workSection = <section className="py-8">
    <div className="md:mx-10 lg:mx-40">
      <div className="grid grid-cols-3 gap-4 grid-block xs-mx">
        <HomeStep
          title="STEP 1"
          description="Gather your thoughts on your current boss or old boss or get insights on your future boss."
          image="images/step1.png"
        />
        <HomeStep
          title="STEP 2"
          description="Complete our simple review card anonymously."
          image="images/step2.png"
        />
        <HomeStep
          title="STEP 3"
          description="Hit submit and change the dynamic in the workforce— one boss - employee relationship at a time."
          image="images/step3.png"
        />
      </div>
    </div>
  </section>

  const goodbossSection = <HomeLeaderboard
    title="Top Good Bosses"
    endpoint="https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/best-bosses"
  />

  const badbossSection = <HomeLeaderboard
    title="Top Bad Bosses"
    endpoint="https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/worst-bosses"
  />

  return (
    <main onClick={onHide}>
      <Search autocomplete={true} show={count} />
      <div className="space-y-4 ... md:hidden lg:hidden">
        <Blurb onClick={clickEvent} name="work" bg="bg-secondary xs-py-1" text="How it works." />
        <div>
          {work && workSection}
        </div>
        <Blurb onClick={clickEvent} name="goodboss" bg="bg-info xs-py-1" text="Top Good Bosses" />
        <div ref={goodRef}>
          {goodboss && goodbossSection}
        </div>
        <Blurb onClick={clickEvent} name="badboss" bg="bg-info xs-py-1" text="Top Bad Bosses" />
        <div ref={badRef}>
          {badboss && badbossSection}
        </div>
        <Blurb onClick={clickEvent} name="review" bg="bg-primary xs-py-1" text="Leave a Review" />
      </div>
      <div className="desktop">
        <Blurb text="How it works." bg="bg-secondary" />
        {workSection}
        <section className="bg-info py-8">
          <div className="container mx-auto flex justify-center">
            {goodbossSection}
            {badbossSection}
          </div>
        </section>
      </div>
    </main >
  );
};

export default Home;
