import { ContactFormGroup } from "./ContactFormGroup";
import { ContactFormLabel } from "./ContactFormLabel";



export const ContactFormSelect = ({ label, options, name, errors, register, ...props }) => {
  const handleInputChange = (e) => {
    if(props && props.onInputChange) {
      props.onInputChange(e)
    }
  }
  return (
    <ContactFormGroup>
      <ContactFormLabel>{label}</ContactFormLabel>
      <select
        className="border border-black rounded-xl text-md py-5 px-6 w-full outline-none mt-2 text-gray-400 bg-none"
        defaultValue="none"
        {...register}
        onChangeCapture={handleInputChange}
      >
        <option value="none" disabled hidden>
          Select
        </option>
        {options.map((res,i) => (
          <option key={i} value={res.value} >
            {res.value}
          </option>
        ))}
      </select>
      {errors && errors[name] && (
        <div className="mb-3 text-normal text-red-500">
          {errors[name].message}
        </div>
      )}
    </ContactFormGroup>
  );
};
