import { useState, useEffect } from 'react';

export const Pagination = ({ totalPage, passCurrentPage, page }) => {
    const [currentPage, setCurrentPage] = useState(page);

    useEffect(() => {
        setCurrentPage(page)
    }, [page])

    useEffect(() => {
            passCurrentPage(currentPage)
    }, [currentPage])

    const goToNextPage = () => {
        setCurrentPage((page) => page + 1);
    }

    const goToPreviousPage = () => {
        setCurrentPage((page) => page - 1);
    }

    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    }

    const getPaginationGroup = () => {
        const customPages = [1, 2, 3, 4, 5, 6]
        return totalPage < 7 ? customPages.slice(0, customPages.indexOf(totalPage)+1) : customPages;
    };


    return (
        <div className="mt-20 pagination flex justify-center">
            <i onClick={goToPreviousPage} className={`cursor-pointer prev green-c fas fa-arrow-left text-xl ${currentPage === 1 ? 'disabled' : ''}`}></i>
            {getPaginationGroup().map((item, index) => (
                <div
                    key={index}
                    onClick={changePage}
                    className={`mx-3 cursor-pointer paginationItem ${currentPage === item ? 'active' : null}`}
                >
                    <span className='lg-font-16p'>{item}</span>
                </div>
            ))}
            <i onClick={goToNextPage} className={`cursor-pointer next fas fa-arrow-right text-xl ${(currentPage === totalPage) ? 'disabled' : ''}`}></i>
        </div>
    )
}