import { ContactFormLabel } from "./ContactFormLabel";

export const ContactFormTextArea = ({ label, placeholder, name, errors, register, children, maxLength, ...props }) => {
  const textareaChange = (e) => {
    if (props && props.onInputChange) {
      props.onInputChange(e)
    }
  }
  return (
    <div>
      <ContactFormLabel>{label}</ContactFormLabel>
      <textarea
        spellCheck="true"
        className="w-full border border-black rounded-xl  py-5 px-6 w-full outline-none mt-2 lg-height"
        placeholder={placeholder}
        onChangeCapture={textareaChange}
        maxLength={maxLength}
        rows="12"
        {...register}
      ></textarea>
      {children}
      {errors && errors[name] && (
        <div className="mb-3 text-normal text-red-500">
          {errors[name].message}
        </div>
      )}
    </div>
  );
};
