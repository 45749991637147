import { FooterIcon } from "./FooterIcon";

export const Footer = () => {
  return (
    <footer className="md:bg-primary py-8 xs-py-1 margin-bottom">
      <div className="container mx-auto text-center text-white">
        <div className="desktop">
          <h2 className="text-7xl font-bold mb-4">
            review my <span className="text-secondary">boss.</span>
          </h2>
          <p className="w-2/5 mx-auto mb-4 lg-font-18p">
            Fostering stronger, more productive relationships between leaders and
            the people on their teams.
        </p>
        </div>
        <div className="col-12 md:mb-8">
          <FooterIcon href="https://www.instagram.com/reviewmyboss/" type={FooterIcon.type.INSTAGRAM} />
        </div>
        <p className="font-lato-regular green-c lg-font-10p">© ReviewMyBoss</p>
      </div>
    </footer>
  );
};
