import { ContactFormGroup } from "./ContactFormGroup";
import { ContactFormLabel } from "./ContactFormLabel";


export const ContactFormInput = ({ label, type, placeholder, name, errors,register, ...props }) => {
  const handleInputChange = (e) => {
    if(props && props.onInputChange) {
      props.onInputChange(e)
    }
  }
  return (
    <ContactFormGroup>
        <ContactFormLabel>{label}</ContactFormLabel>
        <input onChangeCapture={handleInputChange}
          autoComplete="off"
          name={name}
          className="border border-black rounded-xl p-4 w-full outline-none mt-2 mb-8"
          type={type}
          placeholder={placeholder}
          {...register}
        />
        {errors && errors[name] && (
          <div className="mb-3 text-normal text-red-500">
            {errors[name].message}
          </div>
        )}
    </ContactFormGroup>
  );
}