import { useRef } from "react";
import { useHistory } from "react-router-dom";


export const Search = () => {

  const history = useHistory();
  const reviewRef = useRef(null);

  const onSearchsub = () => {
    history.push("/searchsub")
  }

  return (
    <section className="relative">
      <video
        disableRemotePlayback
        autoPlay
        muted
        loop
        playsInline
        src="images/home-video.mp4"
        className="desktop md:absolute object-cover xs-pt-64p h-full md:-z-10 w-full"
      ></video>
      <video
        disableRemotePlayback
        autoPlay
        muted
        loop
        playsInline
        src="images/home-video-small.mp4"
        className="md:hidden md:absolute object-cover h-full md:-z-10 w-full xs-pt-64p xs-h-285p"
      ></video>
      <div ref={reviewRef} className="container mx-auto xs-pt-1 xs-pb-2 md:pt-64 md:pb-32">
        <div className="font-extrabold font-2_5r md:text-6xl text-primary text-center xs-mb-1 mb-8">
          review my <span className="text-secondary">boss.</span>
        </div>
        <div className="text-center xs-mb-1 mb-8">
          <i className="fas fa-search text-sm absolute text-search xs-mt-0_5 md:mt-4 ml-2" ></i>
          <input
            autoComplete="off"
            type="text"
            name="search"
            className="border border-black px-8 py-3 font-9p font-lato width-19 md:w-7/12 lg-w-28 shadow-xl"
            placeholder="Search for Company or Person"
            onClick={onSearchsub}
          />

        </div>
      </div>
    </section>
  );
};
