import { useState } from 'react';


const useDebounce = () => {
    const [typingtimeout, setTypingtimeout] = useState("");

      function debounce (func, wait = 500) {
        clearTimeout(typingtimeout)
        let timeout  = setTimeout(()=> func(), wait)
        setTypingtimeout(timeout)
      }
      return debounce;
}

export default useDebounce;