import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { capitalize } from '../util/helpers';
import useDebounce from "./useDebounce";
import "../scss/searchsub.scss";
import { scrollToPosition } from '../util/helpers';


export const Searchsub = ({ show, scrollToReview, }) => {

    const history = useHistory();
    const searchRef = useRef();
    const [search, setSearch] = useState("");
    const debounce = useDebounce();
    const reviewRef = useRef(null);
    const [cursor, setCursor] = useState(-1);
    const [userlist, setUserList] = useState([]);
    const inputRef = useRef(null);
   

    useEffect(() => {
        emptyList();
    }, [show])

    useEffect(()=>{
        inputRef.current.focus();
        scrollToPosition(0, 0);
      }, []);

    const searchHandler = (type, value) => {
        emptyList();
        if (value)
            scrollToReview && scrollToReview(reviewRef.current.offsetTop)
        history.push(`/results?${type}=${value}`);
    };


    const onChangeEvent = (e) => {
        const text = e.target.value
        setSearch(text)
        debounce(() => {
            if (text.length > 0) {
                fetch(
                    `https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/search-homepage/?search=${text.replaceAll('&', '%26')}`
                ).then((response) => response.json())
                    .then((res) => {
                        const trigger = sessionStorage.getItem('trigger')
                        if (!Number(trigger) && e.target.value.length !== 0) {
                            res.person.forEach(per => {
                                per.name = per.full_name;
                                per.type = 'boss';
                            })
                            res.company.forEach(com => com.type = 'company');
                            res.company.push({ name: 'See all results', type: 'allresults' })
                            setUserList([...res?.person, ...res?.company])
                        } else {
                            emptyList();
                        }
                    });
            }
            if (e.target.value.length === 0) {
                emptyList();
            }
        });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setCursor(-1);
            const type = userlist[cursor]?.type || 'allresults';
            const name = userlist[cursor]?.name
            const value = type !== 'allresults' ? name : searchRef.current.value;
            if (type !== 'allresults') {
                setSearch(name)
            }
            sessionStorage.setItem('trigger', 1)
            searchHandler(type, value);
        } else {
            sessionStorage.setItem('trigger', 0)
        }
        if (event.key === "ArrowDown") {
            setCursor(c => (c < userlist.length - 1 ? c + 1 : c))
        }
        if (event.key === "ArrowUp") {
            setCursor(c => (c > 0 ? c - 1 : 0))
        }
    }

    
    const selectSuggesstion = (value, type) => {
        const searchValue = type === 'allresults' ? search : value
        if (value) {
            if (type !== 'allresults') {
                setSearch(value)
            }
            scrollToReview && scrollToReview(reviewRef.current.offsetTop)
            history.push(`/results?${type}=${searchValue}`);
            emptyList();
        }
    }

    const emptyList = () => {
        setUserList([]);
    }
    const onBack = () => {
        history.push("/")
    }
    const onDelete = () => {
        setSearch("");
        emptyList();
    }
   function onFocus(event) {
     if (event.target.autocomplete) {
            event.target.autocomplete = "off";
        }

    }
    return (
        <section className="relative section">
            <div ref={reviewRef} className="main-div">
                <div className="text-center xs-mb-1 mb-8 mobile position-fi">
                    <button onClick={onBack}> <span className="arrow">&#8592;</span> </button>
                    <input
                        autoComplete="off"
                        type="text"
                        name="search"
                        className="border border-black px-8 py-3 font-9p font-lato width-19 md:w-7/12 lg-w-28 shadow-xl input-box"
                        placeholder="Search for Company or Person"
                        ref={searchRef}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => onChangeEvent(e)}
                        value={search}
                        searchs={true}
                        ref={inputRef}
                        autoFocus ={true}
                        onFocus={onFocus}
                    />{search.length > 0 && <button onClick={onDelete}><span className="close-arrow">&#x2715;</span></button>}

                </div> <div className="text-center xs-mb-1 mb-8 desk">
                    <button onClick={onBack}> <span className="arrow">&#8592;</span> </button>{" "}
                    <input
                        autoComplete="off"
                        type="text"
                        name="search"
                        className="border border-black px-8 py-3 font-9p font-lato width-19 md:w-7/12 lg-w-28 shadow-xl input-box"
                        placeholder="Search for Company or Person"
                        ref={searchRef}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => onChangeEvent(e)}
                        value={search}
                        searchs={true}
                        autoFocus ={true}
                        onFocus={onFocus}
                    />{search.length > 0 && <button onClick={onDelete}><span className="close-arrow">&#x2715;</span></button>}

                </div>
                <div className="flex justify-center mt-n-10px">
                    <div className="dropdown-content  width-17 md:w-7/12 lg-w-28 drop">
                        {userlist.length > 0 && userlist.map((item, i) => {
                            return (
                                <div className={`flex ${i === cursor ? 'keyboard' : ''}`} key={i}>
                                    <>
                                        {item.type === 'boss' ? <img className="w-8 ml-4" src="images/person.svg" alt="company" /> : item.type === 'company' ?
                                            <img className="w-8 ml-4" src="images/company.svg" alt="company" /> : ''}
                                        <span className={`width-70p md:w-6/12 ml-4 truncate ${item.type !== 'allresults' ? 'text-black' : 'text-primary'}`} onClick={() => selectSuggesstion(capitalize(item.name), item.type === 'allresults' ? 'allresults' : item.type === 'boss' ? 'boss' : 'company')}>{capitalize(item.name)} {item.company ? `| ${capitalize(item.company)}` : ''}</span>
                                    </>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};
