import { classNames } from "../util/helpers";

export const Blurb = ({ text, name, bg, ...props }) => {
 const clickEvent = () => {
   if(props.onClick){
     props.onClick(name);
   }
 }
    return (
      <section className={classNames("py-6", bg)}>
        <div className="container mx-auto">
          <h2 onClick={clickEvent} className="xs-mx text-white text-center font-20p lg-font-40p font-bold font-poppins">
            {text}
          </h2>
        </div>
      </section>
    );
}