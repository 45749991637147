import { ContactForm } from "../components/ContactForm";

export const ContactUs = (props) => {
  return (
    <main>
      <section className="py-32">
        <div className="xs-mx-2 md:mx-10 lg:mx-40 my-12">
          <ContactForm {...props} />
        </div>
      </section>
    </main>
  );
};
