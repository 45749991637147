import { Title } from "./Title";

export const HomeStep = ({ title, description, image }) => {
  return (
    <div className={`flex flex-col items-center`}>
      <div className="text-center md:w-48 lg:w-80 mb-10 h-28">
        <Title className="mb-2 font-poppins font-20p">{title}</Title>
        <p className="font-lato-medium  width-18 font-18p">{description}</p>
      </div>
      <img className="m-width-50p mb-8" src={image} alt="step 1" />
    </div>
  );
};
