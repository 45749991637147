import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { scrollToPosition } from "../util/helpers";

const NavBar = ({count}) => {
  useEffect(() => {
      setShow(false);
  }, [count])
  const pages = [
    ["HOME", "/"],
    ["REVIEW", "/review"],
    ["PRIVACY", "/privacy"],
    ["CONTACT US", "/contactus"],
  ];
  const mobPages = [
    ["Home", "/"],
    ["Review", "/review"],
    ["Privacy", "/privacy"],
    ["Contact Us", "/contactus"],
  ]
  const [show,setShow] = useState(false)

  const hide='md:hidden hidden'
  const display='md:hidden inset-y-0 right-0  fixed mobile-menu mt-14 z-50'
  const showMenu = () => {
    setTimeout(() => {
      setShow(!show)
    },500)
  }

  const onNavigation = () => {
    scrollToPosition(0, 0);
    setShow(false);
  }

  return (
    <>
      <nav className="bg-primary py-2 w-full z-50 mb-25 fixed ...">
      <div className="container mx-auto flex justify-between">
        <NavLink to="/" className="text-5xl xs-ml font-bold text-white md:ml-20 lg:ml-40">
          rm<span className="text-secondary">b.</span>
        </NavLink>
        <div className="hidden md:flex items-center md:mr-20 lg:mr-28">
          {pages.map(([key, value]) => {
            return (
              <NavLink
                exact
                key={key}
                to={value}
                className="text-white lg-font-13p font-semibold mx-4 hover:text-secondary"
                activeClassName="text-secondary"
                onClick={onNavigation}
              >
                {key}
              </NavLink>
            );
          })} 
        </div>
          <i onClick={()=>showMenu()} className="fas fa-bars text-3xl md:hidden flex items-center text-white font-semibold mx-2 menu-button"></i>
      </div>
    </nav>
    <div className={show ? display : hide}>
          {mobPages.map(([key, value]) => {
            return (
              <NavLink
                onClick={onNavigation}
                exact
                key={key}
                to={value}
                className="md:hidden bg-menu block py-4 pl-10 pr-4 text-sm text-right font-extrabold text-white border-b"
                activeClassName="text-secondary"
              >
                {key}
              </NavLink>
            );
          })} 
      </div>
    </>
  );
};

export default NavBar;
