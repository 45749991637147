import { Rating } from '../components/Rating';
import { capitalizeFirstLetter } from '../util/helpers';
export const BossCard = ({ name, company, title, stars, ...props }) => {
  const clickEvent = () => {
    if(props && props.onClick) {
      props.onClick();
    }
  }
  const companyDetails = `${capitalizeFirstLetter(company)} | ${capitalizeFirstLetter(title)}`
  const isMobile = ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) ? true: false
  const maxlimit = isMobile ? 35 : 45
  return (
    <div onClick={clickEvent} className="bg-white border border-black rounded-3xl flex flex-col items-center p-4 mb-8 lg:w-96 last:mb-0 h-40 cursor-pointer">
      <h4 className="text-xl font-bold mb-2 font-poppins">{capitalizeFirstLetter(name)}</h4>
      <h5 className="text-sm  border-black pb-3 mb-3  font-lato-regular">{((companyDetails).length > maxlimit) ? (((companyDetails).substring(0,maxlimit-3)) + '...') : companyDetails }</h5>
      <div className="border-b-1_5 border-primary mb-2 w-10/12"></div>
      <div className="flex">
      <Rating rating={stars} starClass="w-8"/>
      <div className="text-lg flex items-center mt-1.5 ml-2 font-bold">{stars.toFixed(1)}</div>
      </div>
    </div>
  );
};
