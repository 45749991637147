import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Blurb } from "../components/Blurb";
import { RuleCard } from "../components/RuleCard";
import { Button } from "../components/Button";
import { Title } from "../components/Title";
import { scrollToPosition } from "../util/helpers";
import '../scss/review.scss';

export const Review = () => {
  const history = useHistory();

  useEffect(() => {
    scrollToPosition(0, 0);
  }, [])

  const onSubmit = () => {
      history.push("/survey");
      scrollToPosition(0, 0);
  };



  return (
    <main>
      <section className="py-20">
        <div className="xs-mx-2 md:mx-10 lg:mx-52">
          <Title className="text-center my-12 desktop">
            Taking the power back, with your review, using our collective voice.
          </Title>
          <div className="flex justify-around justify-unset grid-block">
            <div className="w-2/5 w-unset">
              <RuleCard
                mainSrc="images/dos.png"
                bulletSrc="images/check.svg"
                rules={[
                  "Discuss your bosses professional abilities",
                  "Focus on integrity, confidence, mentorship, accountability, delegation, and empowerment",
                  "Check your grammar and spelling",
                ]}
                color={RuleCard.color.PRIMARY}
              />
            </div>
            <div className="w-2/5 w-unset">
              <RuleCard
                mainSrc="images/donts.png"
                bulletSrc="images/times.svg"
                rules={[
                  "Use profanity, name calling, or derogatory terms",
                  "Call out any employees by name",
                  "Accuse anyone of any illegal activities",
                ]}
                color={RuleCard.color.SECONDARY}
              />
            </div>
          </div>
        </div>
      </section>
      <Blurb text="This is strictly business. Keep it professional." bg="bg-secondary" />
        <section className="py-20 ">
          <div className="md:mx-10 lg:mx-52 xs-mx-2 ">
            <div className="flex justify-center review-button">
              <button className="rounded-full text-white font-lato bg-primary w-80 p-4 font-semibold lg-font-25p width-15 start-review" 
              onClick={onSubmit}>
              Start Review
              </button>
              </div>
          </div>
        </section>
    </main >
  );
};
