export const questions = 
{
  "total_questions": 12,
  "question_bank": [
    {
      "headings": [
        {
          "heading": "Fairness - Which one of the below statements aligns most closely to your assessment of this person?"
        }
      ],
      "position": 0,
      "family": "single_choice",
      "choices": [
        {
          "text": "This manager/boss plays favorites and gives special treatment to people they get along with or like better. They don’t judge the quality or effort of work consistently.",
          "branch": 100
        },
        {
          "text": "This manager/boss does sometimes favor specific people they like/get along with but for the most part is fair about assessing the quality and effort of work.",
          "branch": 100
        },
        {
          "text": "This manager/boss has a strict separation of personal and work relationships. They don’t allow anyone to get close to them and have a tough but consistent way of assessing employees.",
          "branch": 100
        },
        {
          "text": "This manager/boss knows how to assess soft skills and personal qualities. They seem to have a consistent way of assessing quality of work and effort put in which aligns to them having better personal relationships with the high performers on the team.",
          "branch": 100
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Trust & Compensation - Which one of the below best describes this person?"
        }
      ],
      "position": 100,
      "family": "single_choice",
      "choices": [
        {
          "text": "Self-Promoter: 'A manager who is a self-promoter typically takes credit for your work, fights for personal raises internally while putting your financial needs on the backburner'",
          "branch": 200
        },
        {
          "text": "Under the Radar: 'A manager who tries to fly under the radar with senior executives/owners and higher ups. They don’t like to speak up and bring too much attention to the work and accept raises/bonuses if they come without making a big deal of them'",
          "branch": 200
        },
        {
          "text": "Disengaged: 'A manager who doesn’t care much about anything, they might not be an active participant in anything, be unavailable and secretive about raises/bonuses and benefits. It feels like they may get fired any minute or just not show up again'",
          "branch": 200
        },
        {
          "text": "Team Developer: This manager/boss knows how to assess soft skills and personal qualities. They seem to have a consistent way of assessing quality of work and effort put in which aligns to them having better personal relationships with the high performers on the team.",
          "branch": 200
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Leadership Style - Which one of the below statements best describes this person’s leadership style?"
        }
      ],
      "position": 200,
      "family": "single_choice",
      "choices": [
        {
          "text": "This manager/boss is someone who nitpicks at details that don’t matter and is constantly redoing people’s work.",
          "branch": 300
        },
        {
          "text": "This manager/boss is very particular about work that their employees do and has a specific way they like things done but doesn’t interfere in the process of work – only judges the output",
          "branch": 300
        },
        {
          "text": "This manager/boss is open to new ways of working and various styles. They provide good feedback but also are willing to adopt to individual processes if the outcome is good.",
          "branch": 300
        },
        {
          "text": "This manager/boss doesn’t provide much feedback or guidance. They usually let people do whatever they want.",
          "branch": 300
        }
      ]
    },
          {
      "headings": [
        {
          "heading": "Interpersonal & Working Relationships"
        }
      ],
      "position": 300,
      "family": "single_choice",
      "choices": [
        {
          "text": "This manager/boss doesn’t care about anyone but themselves. Their priority is always themselves and that’s it even if they burn bridges and offend people.",
          "branch": 400
        },
        {
          "text": "This manager/boss puts themselves first, their “favorites” second and everyone else (including the organization) last. They are good at getting what their inner circle wants at the expense of the team and/or organization",
          "branch": 400
        },
        {
          "text": "This manager/boss is good at being able to manage their relationships across departments, headquarters etc. They care about their whole team and align goals to the larger organizations.",
          "branch": 400
        },
        {
          "text": "This manager/boss only cares about the organization. They aren’t here to make friends or ensure people are happy. They will go after productivity and profits over all else.",
          "branch": 400
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "f you had to pick three characteristics for this person which three, would you pick from the below? "
        }
      ],
      "position": 400,
      "family": "multiple_choice",
      "choices": [
        {
          "text": "Leader",
          "branch": 500
        },
        {
          "text": "Micro-Manager",
          "branch": 500
        },
        {
          "text": "Selfish",
          "branch": 500
        },
        {
          "text": "Incompetent",
          "branch": 500
        },
        {
          "text": "Fair",
          "branch": 500
        },
        {
          "text": "Fun & Engaging",
          "branch": 500
        },
        {
          "text": "Intelligent",
          "branch": 500
        },
        {
          "text": "Understanding",
          "branch": 500
        },
        {
          "text": "Arrogant",
          "branch": 500
        },
        {
          "text": "Divisive",
          "branch": 500
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Which statement describes your feelings on working with this person again?"
        }
      ],
      "position": 500,
      "family": "single_choice",
      "choices": [
        {
          "text": "Never again",
          "branch": 600
        },
        {
          "text": "Maybe if they change",
          "branch": 600
        },
        {
          "text": "Sure, why not",
          "branch": 600
        },
        {
          "text": "I’d like to work with this person again",
          "branch": 600
        },
        {
          "text": "I’d love to work with this person again",
          "branch": 600
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Which words describe the environment this person creates in a work setting?"
        }
      ],
      "position": 600,
      "family": "single_choice",
      "choices": [
        {
          "text": "Toxic & Terrible",
          "branch": 700
        },
        {
          "text": "Anxiety Ridden & Authoritative",
          "branch": 700
        },
        {
          "text": "Pleasant & Collaborative",
          "branch": 700
        },
        {
          "text": "Engaging & Team Oriented",
          "branch": 700
        },
        {
          "text": "Lackadaisical & Chaotic",
          "branch": 700
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "On a scale of 1 to 10 (1 being the worst and 10 being the best) how would you rate this person as a Leader if the definition is, 'Someone who people on the team and outside the team look to for guidance. Someone who understands people’s needs and aligns them to the organization'."
        }
      ],
      "position": 700,
      "family": "slider",
      "choices": [
        {
          "weight": 1,
          "text": "1",
          "branch": 800
        },
        {
          "weight": 2,
          "text": "2",
          "branch": 800
        },
        {
          "weight": 3,
          "text": "3",
          "branch": 800
        },
        {
          "weight": 4,
          "text": "4",
          "branch": 800
        },
        {
          "weight": 5,
          "text": "5",
          "branch": 800
        },
        {
          "weight": 6,
          "text": "6",
          "branch": 800
        },
        {
          "weight": 7,
          "text": "7",
          "branch": 800
        },
        {
          "weight": 8,
          "text": "8",
          "branch": 800
        },
        {
          "weight": 9,
          "text": "9",
          "branch": 800
        },
        {
          "weight": 10,
          "text": "10",
          "branch": 800
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Which one of the following descriptions best aligns to your relationship with this person?"
        }
      ],
      "position": 800,
      "family": "single_choice",
      "choices": [
        {
          "text": "We have a teacher/student type relationship",
          "branch": 900
        },
        {
          "text": "This person is my mentor and guide",
          "branch": 900
        },
        {
          "text": "This person tries to control me",
          "branch": 900
        },
        {
          "text": "He/She/They are like a friend I trust and get advice from",
          "branch": 900
        },
        {
          "text": "This person is abusive and bad for my mental health",
          "branch": 900
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "If we asked this person how to best describe their relationship with you, how do you think they would answer it?"
        }
      ],
      "position": 900,
      "family": "single_choice",
      "choices": [
        {
          "text": "We have a teacher /student type relationship",
          "branch": 1100
        },
        {
          "text": "This person was one of my mentees",
          "branch": 1100
        },
        {
          "text": "I had to take control of this person’s outputs",
          "branch": 1100
        },
        {
          "text": "He/She/They are like a friend I trust and get advice from",
          "branch": 1100
        },
        {
          "text": "This person is abusive and bad for my mental health",
          "branch": 1100
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Rate this person as a manger/boss on a scale of 1 star to 5 stars. 1 star Being the Worst and 5 stars being the best."
        }
      ],
      "position": 1100,
      "family": "rating",
      "choices": [
        {
          "weight": 1,
          "text": "1",
          "branch": 1
        },
        {
          "weight": 2,
          "text": "2",
          "branch": 1
        },
        {
          "weight": 3,
          "text": "3",
          "branch": 1
        },
        {
          "weight": 4,
          "text": "4",
          "branch": 1
        },
        {
          "weight": 5,
          "text": "5",
          "branch": 1
        }
      ]
    },
    {
      "headings": [
        {
          "heading": "Please describe this person in your own words below:"
        }
      ],
      "position": 1,
      "family": "short_response"
    }
  ]
}