import { useLocation } from "react-router-dom";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const useQuery = () => {
  return new window.URLSearchParams(useLocation().search);
};

export const scrollToPosition = (x, y) => {
  return window.scrollTo(x, y);
}

const charAtCapitalize = (words) => {
  for (var word = 0; word < words.length; word++) {
    var caps = words[word].charAt(0).toUpperCase();
    words[word] = caps + words[word].substr(1);
  }
  return words
}

export const capitalizeFirstLetter = (name) => {
  var words = name.split(" ");
  words = charAtCapitalize(words)
  words = words.join(" ");
  words = words.split(".");
  words = charAtCapitalize(words)
  return words.join(".");
  // return `${name.substring(0,1).toUpperCase()}${name.slice(1)}`

}
export const capitalize = (name, index = 0, res = "") => {
  if (index === 0) {
    res = res + name[index].toUpperCase();
  } else {
    let lastIndex_one = res[index - 1];
    let lastIndex_two = res[index - 2];
    if ((lastIndex_one === ' ' && lastIndex_two === '&') || lastIndex_one === '&' || lastIndex_one === ' ') {
      res = res + name[index].toUpperCase();
    } else {
      res = res + name[index]
    }
  }

  if (res.length === name.length) {
    return res
  } else {
    return capitalize(name, index + 1, res)
  }
}

export const percentage = (partialValue, totalValue)=>{
  return ((partialValue/totalValue) * 100).toFixed(3)
}