import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { BossCard } from "./BossCard";
import { Spinner } from '../components/Spinner';

export const HomeLeaderboard = ({ title, endpoint }) => {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoader(true);
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        setData(data);
      });
  }, [endpoint]);

  return (
    <>
      {loader ? <Spinner /> :
        <div className="mx-8 mb-5">
          <h4 className="text-white text-center lg-font-40p font-bold mb-8 font-poppins">
            {title}
          </h4>
          {data && data.map(({ full_name, company, location, position, rt_avg }, i) => (
            <BossCard
              key={i}
              name={full_name}
              company={company}
              title={position}
              stars={rt_avg}
              onClick={() => {
                history.push(`/profile?full_name=${full_name}&position=${position}&company=${company}&location=${location}`);
              }}
            />
          ))}
        </div>
      }
    </>
  );
};
