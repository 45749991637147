import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ContactFormInput } from "./ContactFormInput";
import { ContactFormSelect } from "./ContactFormSelect";
import { ContactFormTextArea } from "./ContactFormTextArea";
import { Button } from "./Button";
import { useQuery } from "../util/helpers";
import { DialogModal } from '../components/Modal';
import { Recaptcha } from '../components/Recaptcha'
import { scrollToPosition } from '../util/helpers';
import { useHistory } from "react-router";

export const ContactForm = (props) => {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [enabled, setEnabled] = useState(true);
  const [verified, setVerified] = useState("");
  const [isOpen, setIsOpen] = useState(0);
  const query = useQuery();
  const uuid = query.get('uuid');
  const name = query.get('full_name');
  const position = query.get('position');
  const company = query.get('company');
  const location = query.get('location')
  const review_text = props.location?.state?.review_text

  const [data, setData] = useState({
    full_name: "",
    email: "",
    message: "",
    topic: ""
  });

  useEffect(() => {
    scrollToPosition(0, 0)
    if (name && uuid) {
      setValue('full_name', name)
      // setValue()
      setValue('topic', `Flagged Review - ${uuid}`)
      setData({ ...data, 'topic': `Flagged Review - ${uuid}`, 'full_name': name })
    }
  }, [])
  const onSubmit = handleSubmit(data => {
    const { full_name, email, message, topic } = data
    if (uuid && verified) {
      fetch(`https://cx0rbpeji0.execute-api.us-east-2.amazonaws.com/rmb/contact-us?name=${full_name}&email=${email}&topic=${topic}&message=${message}&flag_uuid=${uuid}&review_text=${review_text}&boss_name=${name}&company=${company}&position=${position}&location=${location}&cp=${verified}`)
        .then(res => {
          if (res.status === 200) {
            history.push(`/profile?full_name=${name}&position=${position}&company=${company}&location=${location}`);
          }
        });
    } else if (verified) {
      fetch(`https://cx0rbpeji0.execute-api.us-east-2.amazonaws.com/rmb/contact-us?name=${full_name}&email=${email}&topic=${topic}&message=${message}&cp=${verified}`)
        .then(res => {
          if (res.status === 200) {
            setIsOpen(prevState => prevState + 1);
          }
        });
    }
  });

  const topicOptions = [
    { value: "The website isn't working" },
    { value: "I don't see my review" },
    { value: "Other" }
  ];
  if (uuid) {
    topicOptions.unshift({ value: `Flagged Review - ${uuid}` })
  }

  useEffect(() => {
    if (data.full_name && data.email && data.message && data.topic && verified) {
      setEnabled(false);
    } else {
      setEnabled(true)
    }
  }, [data, verified])

  async function  captchaOnCHange (data) {
    if (data && data.token) {
      setVerified(data.token);
    } else {
      setVerified('');
    }
  }

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  return (
    <div>
      <form onSubmit="handleSubmit(onSubmit)">
        <div className="grid grid-cols-2 gap-14 grid-block">
          <div>
            <ContactFormInput
              name="full_name"
              label="Name"
              type="text"
              onInputChange={onInputChange}
              placeholder="John Doe"
              register={register('full_name', {
                required: 'Name is required',
                pattern: {
                  value: /^[^-\s]([a-zA-Z\s,]*)?$/,
                  message: 'Please enter a valid name',
                }
              })}
              errors={errors}
            />

            <ContactFormInput
              name="email"
              label="Email"
              type="email"
              onInputChange={onInputChange}
              placeholder="email@blank.com"
              register={register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                }
              })}
              errors={errors}
            />
            <ContactFormSelect
              onInputChange={onInputChange}
              name="topic"
              label="Topic"
              options={topicOptions}
              register={register('topic', {
                required: 'Topic is required',
              })}
              errors={errors}
            />
          </div>
          <div>
            <ContactFormTextArea
              name="message"
              label="Message"
              maxLength="900"
              placeholder="Leave your message here..."
              register={register('message', {
                required: 'Message is required'
              })}
              errors={errors}
              onInputChange={onInputChange}
            >
              <div className="text-right mb-5 lg-font-21p">{data.message.length}/900 Characters</div>
            </ContactFormTextArea>
          </div>
        </div>
        <div className="flex justify-center mb-8">
          <Recaptcha captchaOnCHange={captchaOnCHange} />
        </div>
        <div className="flex justify-center">
          <Button enabled={enabled} dim={enabled && Button.opacity.DISABLE} color={Button.color.PRIMARY} size={Button.size.SMALL} onClick={onSubmit}>Submit</Button>
        </div>
        <DialogModal open={isOpen} successText="Contact Form Received Successfully" route="/" />
      </form>
    </div>
  );
};
