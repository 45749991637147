import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import { Pagination } from "../components/Pagination";
import { Rating } from "../components/Rating";
import { Spinner } from "../components/Spinner";
import { useQuery, capitalizeFirstLetter, scrollToPosition } from "../util/helpers";




export const Profile = () => {
  const query = useQuery();
  const full_name = query.get("full_name");
  const position = query.get("position");
  const company = query.get("company");
  const location = query.get("location");
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(false);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [info, setInfo] = useState(null);
  const [lastCount, setLastCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false)
  const dataLimit = 10;
  const emoji = [
    { id: 0, native: 'https://openmoji.org/data/color/svg/1F44D.svg', emid: 1 },
    { id: 1, native: 'https://openmoji.org/data/color/svg/1F923.svg', emid: 2 },
    { id: 2, native: 'https://openmoji.org/data/color/svg/1F92F.svg', emid: 3 },
    { id: 3, native: 'https://openmoji.org/data/color/svg/1F622.svg', emid: 4 },
    { id: 4, native: 'https://openmoji.org/data/color/svg/1F621.svg', emid: 5 }
  ]



  useEffect(() => {
    const comment = []
    if (full_name && position && company && location) {
      fetch(
        `https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/get-profile-person?full_name=${full_name}&position=${position}&company=${company}&location=${location}`
      )
        .then((response) => response.json())
        .then((data) => {
          setCurrentPage(1)
          setPages(Math.ceil(data[0].review.length / dataLimit))
          data.map(res => {
            res.review.map((result, i) => {
              comment.push({
                id: i,
                text: result,
                flag: false,
                emoji: false,
                symbol: [],
                editEmoji: true,
                emoji_1_count: res.emoji_1[i],
                emoji_2_count: res.emoji_2[i],
                emoji_3_count: res.emoji_3[i],
                emoji_4_count: res.emoji_4[i],
                emoji_5_count: res.emoji_5[i],
                emoji_total_count: res.emoji_1[i] + res.emoji_2[i] + res.emoji_3[i] + res.emoji_4[i] + res.emoji_5[i]
              })
              const emoji_1 = res.emoji_1[i]
              const emoji_2 = res.emoji_2[i]
              const emoji_3 = res.emoji_3[i]
              const emoji_4 = res.emoji_4[i]
              const emoji_5 = res.emoji_5[i]
              if (emoji_1) {
                comment[i].symbol.push({ id: 1, native: 'https://openmoji.org/data/color/svg/1F44D.svg' })
              }
              if (emoji_2) {
                comment[i].symbol.push({ id: 2, native: 'https://openmoji.org/data/color/svg/1F923.svg' })
              }
              if (emoji_3) {
                comment[i].symbol.push({ id: 3, native: 'https://openmoji.org/data/color/svg/1F92F.svg' })
              }
              if (emoji_4) {
                comment[i].symbol.push({ id: 4, native: 'https://openmoji.org/data/color/svg/1F622.svg' })
              }
              if (emoji_5) {
                comment[i].symbol.push({ id: 5, native: 'https://openmoji.org/data/color/svg/1F621.svg' })
              }
              res.comment = comment;
            })
          })
          setInfo(data[0])
        });
    }
  }, [full_name, position, company, location]);

  useEffect (() => {
    setLoader(false)
    scrollToPosition(0, 0 )
  }, [info])


 
  if (!info) {
    return <div className="h-80 flex items-center"><Spinner /></div>
  }
  const flagChange = (review) => {
    const data = info;
    const uuid = data.uuid[review.id];
    data.comment[review.id].flag = !data.comment[review.id].flag
    history.push({
      pathname: '/contactus',
      search: `?uuid=${uuid}&full_name=${full_name}&position=${position}&company=${company}&location=${location}`,
      state: { review_text: review.text }
    })
    setInfo(data);
    setFlag(!flag);
  }

  const onEmojiClick = (emojiObject, id) => {
    setLoading(true)
    const data = info;
    if (data.comment[id].editEmoji) {
      fetch(`https://sq8ui148se.execute-api.us-east-2.amazonaws.com/rmb/emoji-update?uuid=${data.uuid[id]}&emid=${emojiObject.emid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        }
      })
        .then((response) => response.json())
        .then(emo => {
          if (emo) {
            data.comment[id].editEmoji = false;
            data.comment[id][`emoji_${emojiObject.emid}_count`]++
            data.comment[id]['emoji_total_count']++
            data.comment[id][`lastcount`] = emojiObject.emid
            const isEmoji = data.comment[id].symbol.some(res => res.native === emojiObject.native)
            if (!isEmoji) {
              data.comment[id].symbol.push({ id: emojiObject.emid, native: emojiObject.native });
            }
            setLastCount(emojiObject.emid)
            setInfo(data);
            data.comment[id].emoji = !data.comment[id].emoji
            setChosenEmoji(!chosenEmoji);
            setLoading(false)
          }
        })
    } else {
      setTimeout(()=> {
        if (lastCount !== emojiObject.emid) {
          data.comment[id][`emoji_${emojiObject.emid}_count`]++
          if (data.comment[id][`lastcount`]) {
            data.comment[id][`emoji_${data.comment[id][`lastcount`]}_count`]--
          }
          data.comment[id][`lastcount`] = emojiObject.emid
        } 
        setLastCount(emojiObject.emid)
        setInfo(data);
        setChosenEmoji(!chosenEmoji);
        setLoading(false)
      }, 500)
    }
  };
  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return info.comment.slice(startIndex, endIndex);
  };

  const getCurrentPage = (e) => {
    setCurrentPage(e);
  }

  return (
    <main>
      <section className="py-24">
        {loader && !info.full_name ? <div className="h-80 flex items-center"><Spinner /></div>  :
        <div className="md:mx-12 lg:mx-60">
          <h2 className="text-6xl text-primary font-bold text-center my-4">
            {capitalizeFirstLetter(info.full_name)}
          </h2>
          <h4 className="lg-font-25p text-primary font-bold text-center width-70p w-3/5 mx-auto">
            {`${capitalizeFirstLetter(info.position)} ${capitalizeFirstLetter(info.company)} | ${capitalizeFirstLetter(info.location)}`}
          </h4>
          <hr className="border-hr my-6" />
          <h3 className="lg-font-35p text-primary font-bold text-center">
            Overall Rating
          </h3>
          <h2 className="lg-font-100p text-primary font-bold text-center">{info.rt_avg}</h2>
          <div className="start flex justify-center">
            <Rating rating={info.rt_avg} starClass="w-16 mb-6" />
          </div>
          <div className="flex justify-center mb-20">
            <Button
              size={Button.size.LARGE}
              color={Button.color.PRIMARY}
              margin={Button.margin.HOME}
              onClick={() => {
                history.push(`/survey?full_name=${info.full_name}&position=${info.position}&company=${info.company}&location=${info.location}`);
              }}
            >
              Leave A Review
            </Button>
          </div>
          <div className="xs-mx-2">
              <div className="loader">
                <div className="text-primary font-bold mb-5 lg-font-22p">{info?.review.length} {info?.review.length > 1 ? 'Reviews' : 'Review'}</div>
                {getPaginatedData().map((res, i) => {
                  return (
                    <div key={i}>
                      <div className="start flex">
                        <Rating rating={info.rt_avg} starClass="w-8 mb-1 mt-8" />
                      </div>
                      <div className='lg-font-18p ml-1'>{res.text}</div>
                      <div className="row flex h-12 ml-1">
                        {emoji.map((emoji) => {
                          return (
                            <div key={emoji.emid} className="emoji flex float-left">
                              {loading ? (
                                <img className="w-8 cursor-pointer" src={emoji.native} alt="emoji" />
                              ) : (
                                <img className="w-8 cursor-pointer" src={emoji.native} onClick={() => onEmojiClick(emoji, res.id)} alt="emoji" />
                              )}
                              <span className="flex items-center mr-8">{`${res[`emoji_${emoji.emid}_count`]}`}</span>
                            </div>
                          )
                        })}
                        <div className="w-full flex items-center justify-end"><i onClick={() => flagChange(res)} className="float-left h-5 w-10 cursor-pointer fa fa-flag text-red-600"></i></div>
                      </div>
                    </div>
                  )
                })}
              </div>
            <Pagination totalPage={pages} page={currentPage} passCurrentPage={getCurrentPage} />
          </div>
        </div>
        }
      </section>
    </main>
  );
};
