import { classNames } from "../util/helpers";

export const RuleCard = ({ mainSrc, bulletSrc, rules, color }) => {
  return (
    <div>
      <img className="mx-auto" src={mainSrc} alt="dos" />
      <ul className={classNames("text-center text-1xl font-bold font-poppins", color)}>
        {rules.map((rule) => {
          return (
            <li key={rule} className="mt-6 mb-6 lg-font-22p">
              <img
                src={bulletSrc}
                className="inline w-6 mr-2"
                alt="checkmark"
              />
              {rule}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RuleCard.color = {
  PRIMARY: "text-primary",
  SECONDARY: "text-secondary",
};
